import { faCheckSquare } from '@fortawesome/pro-duotone-svg-icons'
import {
  faFileArrowDown,
  faSearch,
  faTimes
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBarsFilter,
  faCircleArrowLeft,
  faCircleArrowRight,
  faFaceConfused,
  faPenToSquare,
  faPlusCircle,
  faFolderOpen
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import React, {Fragment, useCallback, useState, useRef, useMemo} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  openCreateProjectOverlay,
  openProjectAdditionalInfoOverlay,
  setAccountActivationOverlay
} from '../../actions/overlay'
import {
  changePage,
  loadProjectByIdSuccess,
  startLoadingProjects,
  startLoadingUpdateName,
  updateProjectPaginationParams
} from '../../actions/project'
import { paginationParamsDefaultDates } from '../../reducers/project'
import {
  allProjectsSelector,
  createProjectSelector,
  isAlgarDeskSelector,
  isBtoBSelector,
  projectPageSelector,
  projectPaginationParamsSelector,
  projectSelector,
  selectedProjectSelector,
  updateProjectNameSelector,
  userSelector,
  worktypesSelector
} from '../../selectors'
import { isMobile } from '../../utils'
import LoadingSpinner, { LoadingSpinnerSmall } from '../loading-spinner'
import PageCreateProject from '../overlay-create-project/page-create-project'
import ErrorPage from '../page-error'
import PageParameter from '../page-parameter'
import { PageCreateProjectContainer, ViewProject } from '../page-project/styled'
import ProjectListFilter from '../project-list-filter'
import {
  EditProjectNameIcon,
  RedirectProjectNameIcon,
  ErrorMessage,
  FormContainer,
  Input,
  SaveProjectNameIcon
} from '../view-details/styled'
import { getProjectStatus } from './helpers'
import ProjectsPagination from './pagination'
import {
  AddressTh,
  bigScrollbarClass,
  BtnScrollHorizontal,
  BtnScrollHorizontalCards,
  ButtonCreateProject,
  ButtonCreateProjectContainer,
  ButtonCreateProjectMobile,
  ButtonDownload,
  ButtonFilter,
  ButtonFilterContainer,
  ButtonsWrapper,
  DateCreationTh,
  DateDepotTh,
  DateFinInstructionTh,
  DateReceptionDpcTh,
  DateTd,
  DesktopTh,
  FilterContainer,
  FilterContainerMobile,
  FolderNameTh,
  FolderTypeTh,
  LoadingContainer,
  ModalFooterContainer,
  NoResultText,
  PaginationContainer,
  ProjectContainer,
  ProjectInfoAdress,
  ProjectInfoName,
  ProjectInfoNameContainer,
  ProjectInfoWorkType,
  ProjectInfoWrapper,
  ProjectLine,
  ProjectListContainer,
  ProjectListWrapper,
  ProjectsListContent,
  DesktopInstructionTh,
  ProjectStatus,
  InstrucTH,
  ProjectStatusWrap,
  ProjectTableHead,
  ProjectTitle,
  SearchAndFilterContainer,
  SearchInput,
  SearchInputContainer,
  StatusTh,
  SubTitle,
  TableBody,
  ValidateButton,
  FilterSelectors,
  FilterBox,
  FilterTitle,
  FilterSubTitle,
  FilterCount,
  ButtonFilterActions,
  FilterIcon,
  FilterInfos
} from './styled'

import TextTooltip from '../tooltip'
import { formatDate } from '../../utils/date'
import { convertJsonToXlsx } from '../../utils/convert-json-to-xlsx'
import PMDCApi from '../../utils/pmdc'
import { fakeProject } from '../../constants/fake-project'
import { DEMO_ROUTE } from '../../constants/router'
import { hasFilterDateSelector } from '../../selectors/project'
import { filterModels } from '../../constants/filters'
import {faArrowDownLong , faArrowUpLong, faArrowsUpDown} from "@fortawesome/pro-solid-svg-icons";

const projectContainerId = 'id__project__container'
const cardsContainerId = 'id__cards__container'
const projectListContainerId = 'id__table__project__list__container'

const tableHeaders = [
  'Nom du dossier',
  'Type(s) de projet(s)',
  'Adresse',
  'Statut',
  'Créé le',
  'Déposé le',
  "Début de l'instruction",
  "Fin d'instruction estimée",
  "Réception d'une DPC",
  'Dépôt des pièces complémentaires',
  'Décision'
]

const PageProjectList = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isDemoProject = history.location.pathname === DEMO_ROUTE
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' })
  const user = useSelector(userSelector)

  const defaultProject = useSelector(selectedProjectSelector)
  const {
    projectsLoading,
    projectsError,
    selectedProjectId: reduxSelectedProjectId
  } = useSelector(projectSelector)

  const projectsWithTotal = useSelector(allProjectsSelector)
  const projects = projectsWithTotal?.data || []
  const projectTotalWithoutFilter = projectsWithTotal?.totalWithoutFilter || 0
  const projectTotalWithFilter = projectsWithTotal?.total || 0
  const paginationParams = useSelector(projectPaginationParamsSelector)
  const { limit, offset, dealStatusTitles, workTypeCodes } = paginationParams
  const showPagination = projectTotalWithoutFilter > limit

  const workTypes = useSelector(worktypesSelector)

  const { createdProject, createProjectLoading } = useSelector(
    createProjectSelector
  )

  const { updateNameLoading, updateNameError } = useSelector(
    updateProjectNameSelector
  )

  const isHasFilterDate = useSelector(hasFilterDateSelector)

  const [selectedProjectId, setSelectedProjectId] = useState('')
  const [editedProject, setEditedProject] = useState(null)
  const [actionFilteredProject, setActionFilteredProject] = useState(false)
  const [name, setName] = useState('')
  const [searchText, setSearchText] = useState(null)
  const [openFilter, setOpenFilter] = useState(false)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [unfilteredProjecstData, setUnfilteredProjectsData] = useState(null)
  const [filteredCounts, setFilteredCounts] = useState([])
  const [filterUsed, setFilterUsed] = useState('')
  const [hoveredFilter, setHoveredFilter] = useState('')

  const isBtoB = useSelector(isBtoBSelector)
  const isDesk = useSelector(isAlgarDeskSelector)
  const projectPage = useSelector(projectPageSelector)

  const [
    projectContenairerScrollPosition,
    setProjectContenairerScrollPosition
  ] = useState('left')

  const [
    cardsContenairerScrollPosition,
    setCardsContenairerScrollPosition
  ] = useState('left')

  const dealStageLostId =
    window.processRuntime.env.HUBSPOT_PIPELINE_STAGES_SALES.lost

  const handleClickEdit = (event, project) => {
    event.stopPropagation()
    setEditedProject(project)
  }

  const handleChangeName = (event) => {
    event.stopPropagation()
    setName(event.target.value)
  }

  const handleSave = (event) => {
    event.stopPropagation()
    event.preventDefault()
    dispatch(startLoadingUpdateName(editedProject, name))
    setEditedProject(null)
  }

  const showPageCreateProject = () => {
    dispatch(changePage('page-create-new-project'))
  }

  const showOverlayCreateProject = () => {
    if (isDemoProject) {
      dispatch(setAccountActivationOverlay())
    } else {
      dispatch(openCreateProjectOverlay())
    }
  }

  const handleProjectChange = useCallback(() => {
    if (selectedProjectId) {
      if (selectedProjectId === fakeProject.id) {
        history.push(DEMO_ROUTE)
      } else {
        history.push(`/projet/${selectedProjectId}`)
        dispatch(changePage('projet'))
      }
    }
  }, [selectedProjectId])

  const handleProjectRedirect = (projectId) => {
    if (selectedProjectId) {
      if (selectedProjectId === fakeProject.id) {
        history.push(DEMO_ROUTE)
      } else {
        history.push(`/projet/${projectId}`)
        dispatch(changePage('projet'))
      }
    }
  }

  const getWorkTypeNameFromIds = (workTypeIds, forTooltip) => {
    let listOfWorkTypes = workTypeIds.map((id) => {
      const workType = workTypes[id].name
      return capitalizeWorkType(workType)
    })
    return listOfWorkTypes.join(forTooltip ? ', ' : ' + ')
  }

  const capitalizeWorkType = (workType) => {
    return workType.charAt(0).toUpperCase() + workType.slice(1)
  }

  const selectProject = (projectId) => {
    if (projectId === selectedProjectId) {
      return setSelectedProjectId('')
    }
    return setSelectedProjectId(projectId)
  }

  const showOverlayAdditionalInfo = (e, project) => {
    e.stopPropagation()
    selectProject(project.id)
    dispatch(loadProjectByIdSuccess(project))
    dispatch(openProjectAdditionalInfoOverlay())
  }

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedProjects = useMemo(() => {
    if (!sortConfig.key) return projects;

    let order = sortConfig.direction;

    console.log(projects);

    if (order === "ascending") {
      switch (sortConfig.key) {

        case 'name':

          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.projectname) {
              projects.sort((a, b) => {
                const nameA = a.projectname ? a.projectname.toLowerCase() : "";
                const nameB = b.projectname ? b.projectname.toLowerCase() : "";
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              });

              break;
            }
          }

          break;
        case 'type':
          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.workTypesIds[0]) {
              projects.sort((a, b) => {
                const workTypesIdsA = a.workTypesIds[0] ? a.workTypesIds[0] : "";
                const workTypesIdsB = b.workTypesIds[0] ? b.workTypesIds[0] : "";

                if (workTypesIdsA < workTypesIdsB) return -1;
                if (workTypesIdsA > workTypesIdsB) return 1;
                return 0;
              });
              break;
            }
          }

          break;
        case 'adress':
          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.address) {
              projects.sort((a, b) => {
                const addressA = a.address ? a.address.toLowerCase() : "";
                const addressB = b.address ? b.address.toLowerCase() : "";

                if (addressA < addressB) return -1;
                if (addressA > addressB) return 1;
                return 0;
              });
              break;
            }
          }

          break;
        case 'creatAt':
          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.createdAt) {
              projects.sort((a, b) => {
                const createdAtA = a.createdAt ? a.createdAt : "";
                const createdAtB = b.createdAt ? b.createdAt : "";

                if (createdAtA < createdAtB) return -1;
                if (createdAtA > createdAtB) return 1;
                return 0;
              });
              break;
            }
          }

          break;
        case 'depot':

          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.dateDepotPc) {
              projects.sort((a, b) => {
                const dateDepotPcA = a.dateDepotPc ? a.dateDepotPc : "";
                const dateDepotPcB = b.dateDepotPc ? b.dateDepotPc : "";

                if (dateDepotPcA < dateDepotPcB) return -1;
                if (dateDepotPctA > dateDepotPcB) return 1;
                return 0;
              });
              break;
            }
          }

          break;

        default:
          console.log(`Error - type colonne non viable`);

      }
    } else if (order === "descending") {
      switch (sortConfig.key) {

        case 'name':

          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.projectname) {

              projects.sort((a, b) => {
                const nameA = a.projectname ? a.projectname.toLowerCase() : "";
                const nameB = b.projectname ? b.projectname.toLowerCase() : "";

                if (nameA < nameB) return 1;
                if (nameA > nameB) return -1;
                return 0;
              });
              break;
            }
          }

          break;
        case 'type':
          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.workTypesIds[0]) {

              projects.sort((a, b) => {
                const workTypesIdsA = a.workTypesIds[0] ? a.workTypesIds[0] : "";
                const workTypesIdsB = b.workTypesIds[0] ? b.workTypesIds[0] : "";

                if (workTypesIdsA < workTypesIdsB) return 1;
                if (workTypesIdsA > workTypesIdsB) return -1;
                return 0;
              });
              break;
            }
          }

          break;
        case 'adress':

          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.address) {

              projects.sort((a, b) => {
                const addressA = a.address ? a.address.toLowerCase() : "";
                const addressB = b.address ? b.address.toLowerCase() : "";

                if (addressA < addressB) return 1;
                if (addressA > addressB) return -1;
                return 0;
              });
              break;
            }
          }

          break;

        case 'creatAt':
          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.createdAt) {

              projects.sort((a, b) => {
                const createdAtA = a.createdAt ? a.createdAt : "";
                const createdAtB = b.createdAt ? b.createdAt : "";

                if (createdAtA < createdAtB) return 1;
                if (createdAtA > createdAtB) return -1;
                return 0;
              });
              break;
            }
          }

          break;

        case 'depot':


          for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (project.dateDepotPc) {
              projects.sort((a, b) => {
                const dateDepotPcB = b.dateDepotPc ? b.dateDepotPc : "";
                const dateDepotPcA = a.dateDepotPc ? a.dateDepotPc : "";
                if (dateDepotPcA < dateDepotPcB) return 1;
                if (dateDepotPcA > dateDepotPcB) return -1;
                return 0;
              });
              break;
            }
          }
          break;

        default:
          console.log(`Error - type colonne non viable`);

      }
    }

    return projects;
  }, [projects, sortConfig]);

  function deepCopy(obj) {
    if (typeof obj !== 'object' || obj === null) {
      return obj
    }

    if (Array.isArray(obj)) {
      return obj.map(deepCopy)
    }

    const newObj = {}
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = deepCopy(obj[key])
      }
    }

    return newObj
  }

  const getWidth = () => {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    )
  }

  const projectLineRef = useRef(null)
  const redirectIconRef = useRef(null)

  const handleToggleFilter = (ev) => {
    ev.stopPropagation()
    setOpenFilter((prev) => !prev)
  }

  const disabled = !(() => !!selectedProjectId)()

  const handleDownloadData = useCallback(async () => {
    let formattedProjects = []

    if (user && user.profile) {
      const { profile, accessToken, decodedAccessToken } = user

      if (projects.length > 0) {
        let copyOfProjects = []

        if (projects.length < projectTotalWithFilter) {
          setDownloadLoading(true)

          const { data: requestData } = await PMDCApi(
            accessToken,
            decodedAccessToken
          ).getProjectsByUserSortedByLast(profile.id, {
            ...paginationParams,
            limit: projectTotalWithFilter,
            offset: 0
          })

          if (requestData && requestData.data) {
            copyOfProjects = requestData.data.filter(
              (p) => p.dealstage !== dealStageLostId
            )
          }

          setDownloadLoading(false)
        } else {
          copyOfProjects = projects.filter(
            (p) => p.dealstage !== dealStageLostId
          )
        }

        copyOfProjects.forEach((project) => {
          const name = project.name || project.projectname || project.dealname
          const status = getProjectStatus(project.dealstage, isBtoB, isDesk)
          const workTypeNames = getWorkTypeNameFromIds(project.workTypesIds)

          const formattedProject = {
            name,
            workTypeNames,
            address: project.address,
            status: status.title,
            createdAt: formatDate(project.createdAt),
            dateExpedition: formatDate(project.dateExpedition),
            dateDepotMontage: formatDate(project.dateDepotMontage),
            dateFinInstruction: formatDate(project.dateFinInstruction),
            dateReceptionDpc: formatDate(project.dateReceptionDpc),
            dateDepotPc: formatDate(project.dateDepotPc),
            decision: formatDate(project.dateDecision),
            numeroInstruction: project.numeroInstruction
          }

          formattedProjects.push(formattedProject)
        })
      }

      const fileName = `${profile.firstName}_${profile.lastName}_Data`
      convertJsonToXlsx(formattedProjects, fileName, `Data`, tableHeaders)
    }
  }, [user, projects, projectTotalWithFilter, paginationParams])

  // Get projects
  React.useEffect(() => {
    if (projects.length === 0) {
      dispatch(startLoadingProjects({ limit, offset }))
    }
  }, [])

  // Get first project by id
  React.useEffect(() => {
    if (projects.length > 0 && !defaultProject) {
      dispatch(loadProjectByIdSuccess(projects[0]))
    }
  }, [projects, defaultProject])

  // Set default name
  React.useEffect(() => {
    if (editedProject) {
      const { name, projectname, dealname } = editedProject
      setName(name || projectname || dealname)
    }
  }, [editedProject])

  // Set selectedProjectId after create project IN the same page
  React.useEffect(() => {
    if (createdProject && createdProject !== null && !createProjectLoading) {
      setSelectedProjectId(createdProject.id)
    }
  }, [createdProject, createProjectLoading])

  // Set selectedProjectId after create project IN ANOTHER PAGE
  React.useEffect(() => {
    setSelectedProjectId(reduxSelectedProjectId)
    document.getElementById(projectListContainerId)
  }, [reduxSelectedProjectId])

  // Load all projects
  React.useEffect(() => {
    const { profile, accessToken, decodedAccessToken } = user
    const fetchData = async () => {
      try {
        const { data: requestData } = await PMDCApi(
          accessToken,
          decodedAccessToken
        ).getProjectsByUserSortedByLast(profile.id, {
          ...paginationParams,
          limit: 31,
          offset: 0
        })
        if (requestData && requestData.data) {
          setUnfilteredProjectsData(requestData.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  React.useEffect(() => {
    if (unfilteredProjecstData) {
      const counts = filterModels.map(filterModel => {
        return getFilteredCount(filterModel.title)
      })
      setFilteredCounts(counts)
    }
  }, [filterModels, unfilteredProjecstData])

  const getValidationNewDates = () => {
    const validationNewDates = deepCopy(paginationParams.dates)
    const validationToday = new Date()
    const validationCurrentDate = validationToday.getTime()
    const validationDateToCheck = new Date(validationToday.setDate(validationToday.getDate() + 10))

    validationNewDates.dateFinInstruction.startDate = validationCurrentDate
    validationNewDates.dateFinInstruction.endDate = validationDateToCheck.getTime()

    return validationNewDates
  }

  const getApprovalNewDates = () => {
    const approvalNewDates = deepCopy(paginationParams.dates)
    const approvalToday = new Date()
    const approvalCurrentDate = approvalToday.getTime()
    const approvalDateToCheck = new Date(approvalToday.setDate(approvalToday.getDate() - 30))

    approvalNewDates.decision.startDate = approvalCurrentDate
    approvalNewDates.decision.endDate = approvalDateToCheck.getTime()

    return approvalNewDates
  }

  const filterConfig = {
    'En préparation': {
      dealstatus: ['1313810', '1313811', '1581258', 'a0ba6de6-9d3c-441e-926b-4c98adc3f231', '4938034', '819129', '819130', '863914', '8955886', '6740078', '65f7b308-c223-4831-8d89-61a4ea8bb98b', '12352081', '769738'],
      dates: paginationParamsDefaultDates,
      workTypeCodes: [],
      dealStatusTitles: []
    },
    'En instruction': {
      dealstatus: ['112854'],
      dates: paginationParamsDefaultDates
    },
    'Pièces complémentaires': {
      dealstatus: ['1334177', '7038669', '862452', '1334178', '1334180', '8957060', '32121943', '20475572', '1334181', '12352082', '10585647', '863776', '862453', '1581269'],
      dates: paginationParamsDefaultDates
    },
    'En approche de validation tacite': {
      dealstatus: [],
      dates: getValidationNewDates()
    },
    'Validations': {
      dealstatus: ['112855', '770298'],
      dates: getApprovalNewDates()
    },
    'Refus': {
      dealstatus: ['1334193', '7038668'],
      dates: getApprovalNewDates()
    },
    'Actions requises': {
      dealstatus: ['1313810', '4938034', '819130', '8955886', '7038669', '1334178', '8957060'],
      dates: paginationParamsDefaultDates
    },
    'Afficher tout': {
      dealStatusTitles: [],
      dealstatus: [],
      workTypeCodes: [],
      dates: paginationParamsDefaultDates
    }
  }

  const filterGroupByTopic = React.useCallback(
    (name) => {
      let config = filterConfig[name]
      if (config) {
        if (name === filterUsed) config = filterConfig['Afficher tout']
        if (name === 'Actions requises') {
          setActionFilteredProject(!actionFilteredProject)
          if (actionFilteredProject) config = filterConfig['Afficher tout']
        }
        if (filterUsed === name) {
          setFilterUsed('')
        } else {
          setFilterUsed(name)
        }
        dispatch(updateProjectPaginationParams({ ...paginationParams, ...config }))
        dispatch(startLoadingProjects({ ...paginationParams, ...config }))
      } else {
        console.log(name)
      }
    },
    [paginationParams, dispatch, projects]
  )
  const getFilteredCount = (name) => {
    const config = filterConfig[name]
    if (config && unfilteredProjecstData) {
      const projectCount = filterProjectsByDealStatus(unfilteredProjecstData, config.dealstatus).length
      return projectCount
    } else {
      return '-'
    }

  }

  const filterProjectsByDealStatus = (projects, dealStatusArray) => {
    return projects.filter(p => dealStatusArray.includes(p.dealstage))
  }

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value)
  }

  const resetSearchText = () => {
    setSearchText('')
  }

  // Run search by text
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(
        updateProjectPaginationParams({
          ...paginationParams,
          queryString: searchText ?? null
        })
      )
      if (searchText) {
        dispatch(
          startLoadingProjects({
            ...paginationParams,
            offset: 0,
            queryString: searchText
          })
        )
      } else if (searchText === '') {
        dispatch(
          startLoadingProjects({
            ...paginationParams,
            offset: 0,
            queryString: null
          })
        )
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [searchText])

  const refModalContent = React.useRef()
  const refModalContentMobile = React.useRef()

  const handleClickOutside = (e) => {
    if (
      !(isMobile ? refModalContentMobile : refModalContent).current?.contains(
        e.target
      )
    ) {
      setOpenFilter(false)
    }
  }

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  // Update scrollbar height on hover
  const mousemoveListener = (e) => {
    const element = e.target
    const elementHeight = element.getBoundingClientRect().height
    const vertical = e.pageY - element.offsetTop

    if (vertical > elementHeight + 100) {
      element.classList.add(bigScrollbarClass)
    } else {
      element.classList.remove(bigScrollbarClass)
    }
  }

  React.useEffect(() => {
    const element = document.getElementById(projectContainerId)
    element?.addEventListener('mousemove', mousemoveListener)
    return () => element?.removeEventListener('mousemove', mousemoveListener)
  }, [])

  const projectContenairScrollListener = (e) => {
    const element = e.target
    if (element.scrollLeft > 30) {
      setProjectContenairerScrollPosition('right')
    } else {
      setProjectContenairerScrollPosition('left')
    }
  }

  const cardsContenairScrollListener = (e) => {
    const element = e.target
    if (element.scrollLeft > 10) {
      setCardsContenairerScrollPosition('right')
    } else {
      setCardsContenairerScrollPosition('left')
    }
  }

  React.useEffect(() => {
    const element = document.getElementById(projectContainerId)
    element.addEventListener('scroll', projectContenairScrollListener)
    return () => {
      element?.removeEventListener('scroll', projectContenairScrollListener)
    }
  }, [])

  React.useEffect(() => {
    const element = document.getElementById(cardsContainerId)
    element.addEventListener('scroll', cardsContenairScrollListener)
    return () => {
      element?.removeEventListener('scroll', cardsContenairScrollListener)
    }
  }, [])

  const handleClickScrollArrow = React.useCallback(
    (e) => {
      const element = document.getElementById(projectContainerId)
      e.stopPropagation()

      if (element) {
        if (projectContenairerScrollPosition === 'left') {
          element.scroll({ left: element.scrollWidth, behavior: 'smooth' })
        } else {
          element.scroll({ left: 0, behavior: 'smooth' })
        }
      }
    },
    [projectContenairerScrollPosition]
  )

  const handleCardsClickScrollArrow = React.useCallback(
    (e) => {
      const element = document.getElementById(cardsContainerId)
      e.stopPropagation()

      if (element) {
        if (cardsContenairerScrollPosition === 'left') {
          element.scroll({ left: element.scrollWidth, behavior: 'smooth' })
        } else {
          element.scroll({ left: 0, behavior: 'smooth' })
        }
      }
    },
    [cardsContenairerScrollPosition]
  )

  if (projectsError && projects.length === 0) {
    return <ErrorPage error={projectsError} />
  }

  const renderProjectLine = (project, index) => {
    const isEdit = editedProject && editedProject.id === project.id
    const status = getProjectStatus(project.dealstage, isBtoB, isDesk)

    const projectName = project.name || project.projectname || project.dealname

    const workTypeNames = getWorkTypeNameFromIds(project.workTypesIds)

    const workTypeNamesForTooltip = getWorkTypeNameFromIds(
      project.workTypesIds,
      true
    )
    return (
      <ProjectLine
        ref={projectLineRef}
        onClick={() => selectProject(project.id)}
        className={selectedProjectId === project.id && 'selectedProject'}
      >
        <ProjectInfoNameContainer>
          <ProjectInfoName hidden={isEdit}>
            <ProjectInfoWrapper>
              <span>{projectName}</span>
              {!isMobile &&
                <EditProjectNameIcon
                  size="2x"
                  icon={faPenToSquare}
                  onClick={
                    isEdit || project.id === fakeProject.id
                      ? undefined
                      : (event) => handleClickEdit(event, project)
                  }
                  disabled={isEdit || project.id === fakeProject.id}
                />
              }
              <RedirectProjectNameIcon
                size="2x"
                ref={redirectIconRef}
                icon={faFolderOpen}
                onClick={() => handleProjectRedirect(project.id)}
              />
              {/* {productStamp(project.typeClient)} */}
            </ProjectInfoWrapper>
          </ProjectInfoName>
          {isEdit && (
            <ProjectInfoName>
              <FormContainer>
                <Input
                  type="text"
                  value={name}
                  onChange={handleChangeName}
                  disabled={updateNameLoading}
                  onClick={(event) => event.stopPropagation()}
                  onKeyPress={(e) =>
                    e.key === 'Enter' && name ? handleSave(e) : undefined
                  }
                />
                <SaveProjectNameIcon
                  onClick={name ? handleSave : undefined}
                  icon={faCheckSquare}
                  size="1x"
                  disabled={!name || updateNameLoading}
                />
                {/* {productStamp(project.typeClient)} */}
                {updateNameLoading && !updateNameError && (
                  <LoadingSpinnerSmall size={15} />
                )}
              </FormContainer>
              {updateNameError && !updateNameLoading && (
                <ErrorMessage>{updateNameError.message}</ErrorMessage>
              )}
            </ProjectInfoName>
          )}
        </ProjectInfoNameContainer>
        <ProjectInfoWorkType>
          {workTypeNames.length >= 20 ? (
            <TextTooltip
              element={workTypeNames}
              text={workTypeNamesForTooltip}
              multiline={true}
            />
          ) : (
            <span>{workTypeNames}</span>
          )}
        </ProjectInfoWorkType>
        <ProjectInfoAdress>
          <span>{project.address}</span>
        </ProjectInfoAdress>
        <ProjectStatusWrap>
          {status?.title && (
            <ProjectStatus
              statusColor={status?.color}
              statusBgColor={status?.bgColor}
            >
              {status?.title}
            </ProjectStatus>
          )}
        </ProjectStatusWrap>
        <DateTd>{formatDate(project.createdAt)}</DateTd>
        <DateTd>{formatDate(project.dateExpedition)}</DateTd>
        <DateTd>{formatDate(project.dateDepotMontage)}</DateTd>
        <DateTd>{project.numeroInstruction || '-'}</DateTd>
        <DateTd>{formatDate(project.dateFinInstruction)}</DateTd>
        <DateTd>{formatDate(project.dateReceptionDpc)}</DateTd>
        <DateTd>{formatDate(project.dateDepotPc)}</DateTd>
        <DateTd>
          {formatDate(project.dateDecision)}
        </DateTd>
      </ProjectLine>
    )
  }

  return (
    <Fragment>
      {openFilter && (
        <FilterContainerMobile ref={refModalContentMobile}>
          <ProjectListFilter setOpenFilter={setOpenFilter} />
        </FilterContainerMobile>
      )}
      <ViewProject>
        {isMobile && projectPage === 'page-create-new-project' ? (
          <PageCreateProjectContainer>
            <PageCreateProject showTitle={false} />
          </PageCreateProjectContainer>
        ) : isMobile && projectPage === 'parametres' ? (
          <PageParameter />
        ) : (
          <ProjectListWrapper>
            <FilterSelectors id={cardsContainerId}>
              <BtnScrollHorizontalCards
                position={cardsContenairerScrollPosition}
                onClick={handleCardsClickScrollArrow}
              >
                <FontAwesomeIcon
                  icon={
                    cardsContenairerScrollPosition === 'left'
                      ? faCircleArrowRight
                      : faCircleArrowLeft
                  }
                  size="2x"
                />
              </BtnScrollHorizontalCards>
              {filterModels.map((filterModel, index) => (
                <FilterBox backgroundColor={filterModel.backgroundColor}
                           key={index}
                           onClick={() => getFilteredCount(filterModel.title) !== 0 && filterGroupByTopic(filterModel.title)}
                           onMouseEnter={() => getFilteredCount(filterModel.title) !== 0 && setHoveredFilter(filterModel.title)}
                           onMouseLeave={() => setHoveredFilter('')}
                           canBeFiltered={getFilteredCount(filterModel.title) !== 0}
                >
                  <FilterTitle textColor={filterModel.textColor}>{filterModel.title}</FilterTitle>
                  <FilterSubTitle>{filterModel.subtitle}</FilterSubTitle>
                  <FilterInfos>
                    <FilterCount textColor={filterModel.textColor}>{filteredCounts[index]}</FilterCount>
                    {(filterModel.title === filterUsed || (filterUsed === '' && hoveredFilter === filterModel.title)) &&
                      <FilterIcon isHovered={hoveredFilter === filterModel.title && filterUsed === ''}>
                        <FontAwesomeIcon
                          icon={faBarsFilter}
                        />
                      </FilterIcon>
                    }
                  </FilterInfos>
                </FilterBox>
              ))}
            </FilterSelectors>
            <ProjectsListContent>
              <BtnScrollHorizontal
                position={projectContenairerScrollPosition}
                onClick={handleClickScrollArrow}
              >
                <FontAwesomeIcon
                  icon={
                    projectContenairerScrollPosition === 'left'
                      ? faCircleArrowRight
                      : faCircleArrowLeft
                  }
                  size="2x"
                />
              </BtnScrollHorizontal>
              <SubTitle>
                <SearchAndFilterContainer>
                  <SearchInputContainer>
                    <SearchInput
                      value={searchText || ''}
                      onChange={onChangeSearchText}
                      placeholder="Rechercher un dossier"
                    />
                    {
                      /*Handle icon according text entered*/
                      searchText ? (
                        <FontAwesomeIcon
                          icon={faTimes}
                          size="1x"
                          onClick={() => resetSearchText()}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faSearch} size="1x" />
                      )
                    }
                  </SearchInputContainer>
                  <ButtonFilterContainer>
                    <ButtonFilter
                      onClick={handleToggleFilter}
                      active={dealStatusTitles.length || workTypeCodes.length || isHasFilterDate}
                    >
                      <FontAwesomeIcon icon={faBarsFilter} />
                      <span>Filtres</span>
                    </ButtonFilter>
                    <FilterContainer ref={refModalContent}>
                      {openFilter && (
                        <ProjectListFilter setOpenFilter={setOpenFilter} />
                      )}
                    </FilterContainer>
                  </ButtonFilterContainer>
                  <ButtonFilterActions onClick={() => filterGroupByTopic('Actions requises')} active={actionFilteredProject}>Actions requises <span>{getFilteredCount('Actions requises')}</span></ButtonFilterActions>
                  {/* <ButtonFilterActions onClick={() => filterGroupByTopic('Afficher tout')}>Afficher tout</ButtonFilterActions> */}
                </SearchAndFilterContainer>
                {!isMobile &&
                downloadLoading ?
                  <ButtonDownload>
                    <LoadingSpinnerSmall size={15} borderWidth={2} />
                  </ButtonDownload>
                  :
                  <ButtonDownload onClick={handleDownloadData}>
                    <FontAwesomeIcon icon={faFileArrowDown} />
                    <span>Télécharger</span>
                  </ButtonDownload>
                }

                <ButtonCreateProjectContainer>
                  <ButtonCreateProject onClick={showOverlayCreateProject}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <span>Créer un dossier</span>
                  </ButtonCreateProject>
                  <ButtonCreateProjectMobile
                    onClick={
                      isMobile && isBtoB
                        ? showPageCreateProject
                        : showOverlayCreateProject
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </ButtonCreateProjectMobile>
                </ButtonCreateProjectContainer>
              </SubTitle>
              <ProjectContainer isMobile={false} id={projectContainerId}>
                {projectsLoading && (
                  <LoadingContainer>
                    <LoadingSpinner />
                  </LoadingContainer>
                )}
                <ProjectListContainer id={projectListContainerId}>
                  <ProjectTableHead>
                    <ProjectTitle>
                      <FolderNameTh onClick={() => handleSort('name')}>
                        Nom du dossier
                        {sortConfig.key === 'name' ? (
                          <FontAwesomeIcon
                            icon={sortConfig.direction === 'ascending' ? faArrowUpLong : faArrowDownLong}
                            style={{ marginLeft: '8px' }}
                          />
                        ): (
                          <FontAwesomeIcon
                            icon={faArrowsUpDown}
                            style={{ marginLeft: '8px' }}
                          />
                        )}</FolderNameTh>
                      <FolderTypeTh onClick={() => handleSort('type')}>
                        Type(s) de projet(s)
                        {sortConfig.key === 'type' ? (
                          <FontAwesomeIcon
                            icon={sortConfig.direction === 'ascending' ? faArrowUpLong : faArrowDownLong}
                            style={{ marginLeft: '8px' }}
                          />
                        ): (
                          <FontAwesomeIcon
                            icon={faArrowsUpDown}
                            style={{ marginLeft: '8px' }}
                          />
                        )}</FolderTypeTh>

                      <AddressTh onClick={() => handleSort('adress')}>
                        Adresse
                        {sortConfig.key === 'adress' ? (
                          <FontAwesomeIcon
                            icon={sortConfig.direction === 'ascending' ? faArrowUpLong : faArrowDownLong}
                            style={{ marginLeft: '8px' }}
                          />
                        ): (
                          <FontAwesomeIcon
                            icon={faArrowsUpDown}
                            style={{ marginLeft: '8px' }}
                          />
                        )}</AddressTh>

                      <StatusTh>
                        Statut
                      </StatusTh>

                      <DateCreationTh onClick={() => handleSort('creatAt')}>
                        Créé le
                        {sortConfig.key === 'creatAt' ? (
                          <FontAwesomeIcon
                            icon={sortConfig.direction === 'ascending' ? faArrowUpLong : faArrowDownLong}
                            style={{ marginLeft: '8px' }}
                          />
                        ): (
                          <FontAwesomeIcon
                            icon={faArrowsUpDown}
                            style={{ marginLeft: '8px' }}
                          />
                        )}</DateCreationTh>

                      <DateDepotTh onClick={() => handleSort('depot')}>
                        Envoyé le
                        {sortConfig.key === 'depot' ? (
                          <FontAwesomeIcon
                            icon={sortConfig.direction === 'ascending' ? faArrowUpLong : faArrowDownLong}
                            style={{ marginLeft: '8px' }}
                          />
                        ): (
                          <FontAwesomeIcon
                            icon={faArrowsUpDown}
                            style={{ marginLeft: '8px' }}
                          />
                        )}</DateDepotTh>

                      <DesktopInstructionTh>
                        <span>Début de l'instruction</span>
                        {!isMobile && <TextTooltip text="La date de début d’instruction correspond à la date indiquée sur le récépissé de dépôt du dossier. Il se peut que l’instruction débute avant que l’administration adresse le document, notamment lorsque le dossier est déposé par voie dématérialisée" />}
                      </DesktopInstructionTh>

                      <InstrucTH>
                        Numéro de dossier
                      </InstrucTH>


                      <DateFinInstructionTh>
                        <span>Fin d'instruction estimée</span>
                        {!isMobile && <TextTooltip text="La date de fin d'instruction est calculée selon votre demande d'autorisation d'urbanisme et les délais officiels de l'administration. Elle peut évoluer selon le retour de votre mairie." />}
                      </DateFinInstructionTh>

                      <DateReceptionDpcTh>
                        <span>Réception d'une DPC</span>
                        {!isMobile && <TextTooltip text="Date de récéption d'une Demande de Pièces Complémentaires (DPC)." />}
                      </DateReceptionDpcTh>

                      <DesktopTh>Dépôt des pièces complémentaires</DesktopTh>
                      <DesktopTh>Décision</DesktopTh>

                    </ProjectTitle>
                  </ProjectTableHead>
                  <TableBody>
                    {projects.length > 0
                      && projects
                        .filter((p) => p.dealstage !== dealStageLostId)
                        .map((project, index) => {
                          return (
                            <Fragment key={project.id}>
                              {renderProjectLine(project, index)}
                            </Fragment>
                          )
                        })
                    }
                  </TableBody>
                </ProjectListContainer>
                {(searchText ||
                    workTypeCodes.length > 0 ||
                    dealStatusTitles.length > 0) &&
                  projectsWithTotal.total === 0 && (
                    <NoResultText>
                      <FontAwesomeIcon icon={faFaceConfused} size="1x" />
                      <span>Aucun résultat</span>
                    </NoResultText>
                  )}
              </ProjectContainer>

              <ModalFooterContainer>
                {showPagination && (
                  <PaginationContainer>
                    <ProjectsPagination />
                  </PaginationContainer>
                )}
                <ButtonsWrapper>
                  <ValidateButton
                    disabled={disabled}
                    onClick={handleProjectChange}
                    centered={true}
                  >
                    Ouvrir
                  </ValidateButton>
                </ButtonsWrapper>
              </ModalFooterContainer>

              {/* <FooterContainerMobile showPagination={showPagination}>
                {showPagination && <ProjectsPagination />}
                <ValidateButton
                  disabled={disabled}
                  onClick={handleProjectChange}
                >
                  Valider
                </ValidateButton>
              </FooterContainerMobile> */}
            </ProjectsListContent>
          </ProjectListWrapper>
        )}
      </ViewProject>
    </Fragment>
  )
}

PageProjectList.propTypes = {
  onCancel: PropTypes.func
}

export default PageProjectList
