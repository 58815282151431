export const DEALSTAGES = {
  ...window.processRuntime.env.HUBSPOT_PIPELINE_STAGES_BTOC_BTOBTOC_ARTISAN,
  ...window.processRuntime.env.HUBSPOT_PIPELINE_STAGES_ONBOARDING,
  ...window.processRuntime.env.HUBSPOT_PIPELINE_STAGES_PRODUCTION,
  ...window.processRuntime.env.HUBSPOT_PIPELINE_STAGES_ELECTRA,
  ...window.processRuntime.env.HUBSPOT_PIPELINE_STAGES_SALES,
  ...window.processRuntime.env.HUBSPOT_PIPELINE_STAGES_CONNECTION
}

export const msgActionRequired = "L’avancée de votre dossier nécessite une action de votre part."
export const actionRequise = { color: '#FFC64C', bgColor: 'rgba(255, 189, 49, 0.15)' }
export const montageDossier = { color: '#001556', bgColor: 'rgba(0, 21, 86, 0.15)' }
export const piecesComplementaires = { color: '#260056', bgColor: 'rgba(38, 0, 86, 0.15)' }
export const validationDossier = { color: '#6EB894', bgColor: 'rgba(110, 184, 148, 0.15)' }
export const refusDossier = { color: '#FF5151', bgColor: 'rgba(255, 81, 81, 0.15)' }
export const fallback = { color: '#C4C4C4', bgColor: 'rgba(196, 196, 196, 0.15)' }

export const getProjectStatus = (dealstage, isBtoB, isDesk, pipeline) => {
  let dealstageKey = Object.keys(DEALSTAGES).find(
    (key) => DEALSTAGES[key].id ? DEALSTAGES[key].id === dealstage : DEALSTAGES[key] === dealstage
  )
  console.log('cacapte?')
  console.log(dealstageKey)
  if (dealstageKey === undefined) {
    if (pipeline === window.processRuntime.env.SALES) dealstageKey = 'payementSetup'
  }

  switch (dealstageKey) {
    //#region Action requise
    case 'formWaiting':
    case 'formOnboarding':
      return {
        title: 'Formulaire de départ',
        message: 'Le formulaire de départ est prêt à être complété pour lancer le dossier.',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
      }
    case 'mandateSign':
    case 'waitingForMandate':
      return {
        title: "Attente signature mandat",
        message: 'Le mandat de gestion est en attente de signature. ',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
      }
    case "caseV1Feedback":
    case "caseV2Feedback":
    case "caseV3Feedback":
    case "mayorRequestV1Feedback":
    case "mayorRequestV2Feedback":
    case "mayorRequestUpload":
    case "panelReceived":
    case 'waitFeedbackHabV1':
    case 'waitFeedbackHabV2':
    case 'waitFeedbackHabV3':
    case 'waitFeedbackHabDPCV1':
    case 'waitFeedbackHabDPCV2':
    case 'caseV1SentToCustomer':
    case 'caseV2SentToCustomer':
    case 'caseV3SentToCustomer':
    case 'caseV1FeedbackReceived':
    case 'caseV2FeedbackReceived':
    case 'caseV3FeedbackReceived':
    case 'caseV3CraftingExternal':
    case 'modV1SentToCustomer':
    case 'modV1FeedbackReceived':
    case 'finalisationInitial':
    case 'finalisationDPC':
    case 'caseFinalCraftingExternal':
      return {
        title: 'Attente retour',
        message: 'Le dossier a été livré, vous pouvez désormais indiquer vos retours.',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
      }
    //#endregion Action requise
    //#region Montage dossier
    case 'callOnboarding':
    case 'welcomeCall':
    case 'caseHandledByFacilitator':
      return {
        title: 'Appel de départ',
        message: 'Pensez à bien prendre votre rendez-vous.',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    case 'caseV1Crafting':
    case 'productionV1':
    case 'productionV2':
    case 'productionV3':
    case 'dpcV1':
    case 'dpcV2':
    case 'caseV1CraftingExternal':
      return {
        title: 'Production',
        message: 'Le dossier est en cours de production.',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    case "caseV2Crafting":
    case "caseV3Crafting":
    case 'caseV2CraftingExternal':
      return {
        title: 'Modification',
        message: 'Le dossier est en cours de modification.',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    case "annex":
    case 'thermalStudy':
      return {
        title: "Étude annexes",
        message: "Les annexes nécessaires au dossier sont en cours de production.",
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    case "preparingDispatch":
    case "preparingDematerializedDispatch":
    case "dispatchOrdered":
    case 'casePreparingPrint':
    case 'casePrinted':
    // old impression
    case 'preparingPrint':
    case 'readyToPrint':
    case 'printInProgress':
    case 'dpcPreparingPrint':
    case 'dpcReadyToPrint':
    case 'dpcPrintInProgress':
    case 'printing':
    case 'prepareDematerialization':
    case 'casePrinting':
      return {
        title: "Préparation dépôt",
        message: "Le dépôt du dossier en mairie est en préparation. ",
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    case "caseSent":
      return {
        title: "Dossier envoyé",
        message: "Le dossier a été envoyé en mairie pour instruction. ",
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    case "caseReceived":
    case 'dpcSent':
    case 'dpcReceived':
      return {
        title: "Dossier déposé",
        message: "Le dossier a été déposé en mairie pour instruction. ",
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }

    case "caseUnderInvestigation":
    case 'caseExaminating':
      return {
        title: "Dossier en instruction",
        message: "Le dossier est en cours d’instruction en mairie. ",
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    //#endregion Montage dossier
    //#region Pieces complementaires
    case "analyzingMayorRequest":
    case "adviceCallMayorRequest":
    case 'dpcAnalyzing':
    case 'dpcCallAdvice':
    case 'modAnalyzing':
    case 'modRequired':
      return {
        title: "Analyse compléments",
        message: "La demande de pièces complémentaires reçue est en cours d’analyse.",
        color: piecesComplementaires.color,
        bgColor: piecesComplementaires.bgColor,
      }
    case "mayorRequestV1Crafting":
    case "mayorRequestAnnex":
    case "modV1CraftingExternal":
      return {
        title: "Production compléments",
        message: "Les pièces complémentaires sont en cours de production.",
        color: piecesComplementaires.color,
        bgColor: piecesComplementaires.bgColor,
      }
    case "mayorRequestV2Crafting":
      return {
        title: "Modification compléments",
        message: "La modification des pièces complémentaires est en cours de production.",
        color: piecesComplementaires.color,
        bgColor: piecesComplementaires.bgColor,
      }
    case "mayorRequestSent":
      return {
        title: "Compléments envoyés",
        message: "Les pièces complémentaires ont été envoyées en mairie.",
        color: piecesComplementaires.color,
        bgColor: piecesComplementaires.bgColor,
      }
    case "mayorRequestReceived":
    case "mayorRequestPreparingDispatch":
    case "mayorRequestDispatchOrdered":
    case "mayorRequestPrinting":
    case 'modPrepartingPrint':
    case 'modSent':
    case 'modPrinting':
    case 'modPrinted':
    case 'modReceived':
    case 'modAnalyzingDematerialized':
    case 'modDematerialization':
    case 'modDematerializatedReceived':
    case 'modReceivedExpired':
      return {
        title: "Compléments déposés",
        message: "Les pièces complémentaires ont été déposées en mairie.",
        color: piecesComplementaires.color,
        bgColor: piecesComplementaires.bgColor,
      }
    case "mayorRequestPreparingDematerializedDispatch":
      return {
        title: "Préparation dépôt",
        message: "Le dépôt des pièces complémentaires en mairie est en préparation.",
        color: piecesComplementaires.color,
        bgColor: piecesComplementaires.bgColor,
      }
    //#endregion Pieces complementaires
    //#region Validation dossier
    case "accepted":
    case "qrCodeCheck":
    case "qrCodeOrdering":
    case "qrCodeDispatch":
    case 'caseProcessedAccepted':
    case 'connectionTempo':
      return {
        title: "Dossier validé",
        message: "Félicitations ! Votre dossier a été validé par l'Administration.",
        color: validationDossier.color,
        bgColor: validationDossier.bgColor,
      }
    case "panelSent":
    case 'validPushQR':
    case 'permitBoardSent':
      return {
        title: "Panneau expédié",
        message: "Votre panneau d’affichage est en cours d’acheminement.",
        color: validationDossier.color,
        bgColor: validationDossier.bgColor,
      }
    case 'pushQRCode':
    case 'qrCodeACommander':
    case 'qrCodeCommander':
      return {
        title: "Panneau en préparation",
        message: "Votre panneau d'affichage est en cours de préparation.",
        color: validationDossier.color,
        bgColor: validationDossier.bgColor,
      }
    case 'panelInPreparation':
    case "panelPreparing":
    case "panelReady":
    case 'permitBoardPreparing':
    case 'permitBoardPrepared':
      return {
        title: "Panneau en préparation",
        message: "Votre panneau d’affichage est en cours de préparation.",
        color: validationDossier.color,
        bgColor: validationDossier.bgColor,
      }
    case "panelReceived":
    case 'permitBoardReceived':
      return {
        title: "Panneau reçu",
        message: "Votre panneau d'affichage vous a été remis.",
        color: validationDossier.color,
        bgColor: validationDossier.bgColor,
      }
    case "panelSet":
    case 'permitBoardInstalled':
    case 'mayorDeny':
      return {
        title: "Dossier clôturé",
        message: "Le dossier est désormais clôturé. ",
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
      }
    //#endregion Validation dossier
    //#region Refus dossier
    case "refusalAnalysis":
    case 'caseAnalysingDeny':
    case 'callAdviceDeny':
    case 'deskDenied':
    case 'caseProcessedDenied':
      return {
        title: "Attente retour",
        message: "Le refus du dossier est en cours d’analyse.",
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
      }
    case "refusalCall":
    case 'caseAnalysingDeny':
      return {
        title: "Appel refus",
        message: "Votre facilitateur va vous appeler. N’oubliez pas de prendre votre rendez-vous.",
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
      }
    case "mayorRefusal":
      return {
        title: "Refus",
        message: "Le dossier a été refusé par la mairie.",
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
      }
    //#endregion Refus dossier

    //#region OLD STATUS
    case 'signup':
    case 'prospects':
    case 'callTrying':
    case 'callScheduled':
    case 'lost':
      return {
        title: 'Appel de découverte',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
        message: "Votre conseiller va vous contacter par téléphone. Cet appel est totalement gratuit !"
      }
    case 'callMissed':
    case 'callScheduled2':
    case 'callMissed2':
    case 'conversation':
    case 'conversation2':
    case 'closing':
    case 'onRadar':
    case 'payementSetup':
      return {
        title: 'Paiement en attente',
        color: '#999999',
        bgColor: fallback.bgColor,
        message: "Votre dossier est en attente de paiement."
      }
    case 'newProject':
      return {
        title: 'Création dossier',
        color: '#0C65BE',
        bgColor: fallback.bgColor
      }
    // cases for Connection Pipeline
    case 'connectionWaitingForm':
      return {
        title: 'Formulaire raccordement',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
        message: "Le formulaire de raccordement est prêt à être complété."
      }
    case 'connectionNew':
      return {
        title: 'Raccordement en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "La demande de raccordement va être transmise."
      }
    case 'connectionSent':
      return {
        title: 'Raccordement en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "La demande de raccordement a été transmise."
      }
    case 'connectionMayorRequestReceived':
      return {
        title: 'Complément raccordement',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "Suite à la demande de raccordement, une demande de complément a été reçue."
      }

    case 'connectionRequest':
      return {
        title: 'Complément raccordemendt',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "Suite à la demande de raccordement, une demande de complément a été reçue."
      }
    case 'connectionSet':
      return {
        title: 'Raccordement en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "Les éléments complémentaires à la demande de raccordement ont été transmis."
      }

    case 'connectionReady':
      return {
        title: 'Raccordement en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: 'Les éléments complémentaires à la demande de raccordement vont être transmis.'
      }
    case 'connectionRefused':
      return {
        title: 'Raccordement refusé',
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
        message: "La demande de raccordement a été refusée."
      }
    case 'connectionWaitingForValidation':
      return {
        title: 'Raccordement en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "La demande de raccordement a été transmise."
      }
    case 'connectionValidated':
      return {
        title: 'Raccordement validé',
        color: validationDossier.color,
        bgColor: validationDossier.bgColor,
        message: "La demande de raccordement a été validée."
      }
    case 'consuelWaitingCertificate':
      return {
        title: 'Attente consuel',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
        message: "La demande de raccordement a été validée."
      }
    case 'consuelFeedback':
      return {
        title: 'Formulaire consuel',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
        message: "La demande de raccordement a été validée."
      }

    case 'connectionNewConsuelCertificate':
      return {
        title: 'Consuel en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "L'attestation Consuel va être commandée."
      }

    case 'connectionConsuelCertificateOrdered':
      return {
        title: 'Consuel en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "La commande d'attestation Consuel est en cours de traitement. Dès que vous avez un retour, ajoutez le document sur votre tableau de bord."
      }
    case 'connectionConsuelRequest':
      return {

        title: 'Complément Consuel',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "Une demande de complément a été émise suite à la demande d'attestation Consuel. Au besoin, contactez votre Account Manager."
      }
    case 'connectionConsuelSet':
      return {
        title: 'Consuel en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "La demande d'attestation Consuel est en cours de traitement. Dès que vous avez un retour, ajoutez le document sur votre tableau de bord."
      }
    case 'connectionNewProcedure':
      return {
        title: 'Mise en service en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "La mise en service va être transmise."
      }
    case 'connectionNewProcedureSent':
      return {
        title: 'Mise en service en cours',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
        message: "La demande de mise en service de l'installation a été transmise."
      }
    case 'connectionError':
      return {
        title: 'Erreur mise en service',
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
        message: "Une erreur sur la mise en service a été détectée. Au besoin, contactez votre Account Manager."
      }
    case 'connectionConsuelRefused':
      return {
        title: 'Consuel refusé',
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
        message: "La demande d'attestation Consuel a été refusée. Au besoin, contactez votre Account Manager."
      }
    case 'connectionComplete':
      return {
        title: 'Mise en service réalisée',
        color: validationDossier.color,
        bgColor: validationDossier.bgColor,
        message: "La mise en service de l'installation a été effectuée."
      }
    case 'connectionNotSupported':
      return {
        title: 'Raccordement non pris en charge',
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
        message: "La demande de raccordement ne peut pas être prise en charge. Au besoin, contactez votre Account Manager."
      }
    case 'formResponseTimeoutMonth':
      return {
        title: 'Formulaire de départ',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
        message: "Le formulaire de départ est prêt à être complété pour lancer le dossier.",
      }
    case 'withoutContinuation':
      return {
        title: 'Dossier en pause',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
        message: "Le dossier est en pause. Pour le relancer, merci de contacter votre facilitateur.",
      }
    case 'retractionFourteenDays':
      return {
        title: 'Dossier clôturé',
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
        message: "Le dossier est désormais clôturé.",
      }
    case 'returnArchitect':
      return {
        title: 'Dossier clôturé',
        color: validationDossier.color,
        bgColor: fallback.bgColor,
        message: "Le dossier est désormais clôturé.",
      }
    case 'cancelBeforeBilling':
      return {
        title: 'Dossier clôturé',
        color: validationDossier.color,
        bgColor: validationDossier.bgColor,
        message: "Le dossier est désormais clôturé.",
      }
    case 'cancelDuringDelivry':
      return {
        title: 'Dossier clôturé',
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
        message: "Le dossier est désormais clôturé.",
      }
    case 'cityHallRefusal':
      return {
        title: 'Dossier clôturé',
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
        message: "Le dossier est désormais clôturé.",
      }
    case 'analysisAdverse':
      return {
        title: 'Dossier clôturé',
        color: refusDossier.color,
        bgColor: refusDossier.bgColor,
        message: "Le dossier est désormais clôturé.",
      }
    case 'attHabSurAalyseDPC':
      return {
        title: 'Attente retour',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
        message: "L'analyse de la demande de pièces complémentaires a été produite, vous pouvez désormais indiquer vos retours.",
      }
    case 'prepaOnboarding':
    case 'pretPourCallOnboarding':
    case 'callOnboardingManque':
    case 'onboardingAFinaliser':
    case 'attenteDossierCalibrage':
    case 'calibrageEnCours':
    case 'rodage':
    case 'RUNClientsCons':
    case 'clientsPause':
    case 'clientsPerdu':
    case 'leadsInbound':
    case 'leadsAjoutes':
    case 'leadsBase':
    case 'enrichissementAFaire':
    case 'leadsAProspecter':
    case 'priseDeContact':
    case 'tentativeAppelStandard':
    case 'tentativeAppelMobile':
    case 'reponsePriseContact':
    case 'rdvPlanifie':
    case 'rdvManque':
    case 'rdvEffectueQualif':
    case 'rdvFixe':
    case 'deuxiemeRdvManque':
    case 'qualifiePourAcheter':
    case 'contratEnvoye':
    case 'ContratSigne':
    case 'activationMandatSEPASimple':
    case 'abonnementHT':
    case 'mandatSEPAAboActive':
    case 'fermePerdu':
      return {
        title: 'N/A',
        color: fallback.color,
        bgColor: fallback.bgColor,
        message: "Si vous souhaitez lancer un nouveau dossier, merci de cliquer sur \"Créer un dossier\".",
      }

    // handle DPC stages
    case 'dpcReceived':
      if (isDesk) {
        return {
          title: 'Attente retour',
          message: msgActionRequired,
          color: actionRequise.color,
          bgColor: actionRequise.bgColor,
        }
      } else {
        return {
          title: "Instruction",
          message: "Votre dossier est en cours d’instruction à l’Administration.",
          color: montageDossier.color,
          bgColor: montageDossier.bgColor,
        }
      }
    case 'ProdEtudefaisa':
      return {
        title: 'Production',
        message: 'L’étude d\'urbanisme est en cours de production.',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    case 'AttRetourEtudefaisa':
      return {
        title: 'Attente retour',
        message: 'L’étude d\'urbanisme a été livrée, vous pouvez désormais indiquer vos retours.',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
      }
    case 'ProdAPR':
      return {
        title: 'Production',
        message: 'L’APS est en cours de production.',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    case 'AttRetourProdAPR':
      return {
        title: 'Attente retour',
        message: 'L’APS a été livré, vous pouvez désormais indiquer vos retours.',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
      }
    case 'Prod3D':
      return {
        title: 'Production',
        message: 'Les 3D sont en cours de production.',
        color: montageDossier.color,
        bgColor: montageDossier.bgColor,
      }
    case 'AttRetour3D':
      return {
        title: 'Attente retour',
        message: 'Les 3D ont été livrés, vous pouvez désormais indiquer vos retours.',
        color: actionRequise.color,
        bgColor: actionRequise.bgColor,
      }
    //#endregion OLD STATUS
    default:
      return {
        title: "Statut introuvable",
        message: "Il semblerait y avoir un problème avec le statut de votre dossier.",
        color: fallback.color,
        bgColor: fallback.bgColor
      }
  }
}